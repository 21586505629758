import styled from 'styled-components';

export const PTypography = styled.div`
    color: white;
    font-size: .7rem;
    @media ${props => props.theme.media.desktop} {
       font-size: .9rem;
    }
`;

export const PTitleTypography = styled.div`
     font-size: .7rem;
     color: white;
     margin: .25rem 0;
`;

export const H1Typography = styled.h1`
    font-family: 'PP Neue Montreal';
    font-weight: 600;
    text-align: center;
    font-size: 1.4rem;
    color: white;
    @media ${props => props.theme.media.desktop} {
       font-size: 1.7rem;
    }
`;

export const H5Typography = styled.h5`
    font-weight: 700;
    font-size: 1.1rem;
    color: black;
    letter-spacing: 0.5px;
    margin: 1rem 0 .5rem;
    @media ${props => props.theme.media.desktop} {
        font-size: 1.2rem;
    }
`;

export const MainTitle = styled.h2 `
    font-family: 'PP Neue Montreal';
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 0.64;
    letter-spacing: 0.45px;
    margin-bottom: 1.5rem;
    color: #365ca9;
    //color: ${props => props.theme.colors.black};
    @media ${props => props.theme.media.desktop} {
        font-size: 1.4rem;
    }
`;

export const MainSubtitle = styled.h3 `
    font-family: 'PP Neue Montreal';
    font-size: .9rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.35px;
    margin: 1rem 0 0.5rem;
    text-transform: capitalize;
    //color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.black};
    &.no-tranform{
        text-transform: none;
    }
`;

export const MainContent = styled.div `
    color:  ${props => props.theme.colors.black};
    line-height: 1.5;
    letter-spacing: 0.3px;
    font-size: .8rem;
    @media ${props => props.theme.media.desktop} {
       font-size: .9rem;
    }
`;

export const MainContentEmpty = styled(MainContent) `
    color:  ${props => props.theme.colors.disabled};
    font-size: .75rem;
    @media ${props => props.theme.media.desktop} {
       font-size: .8rem;
    }
`;
