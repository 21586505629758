import styled from 'styled-components';
import {motion} from 'framer-motion';

export const Main = styled(motion.main)`
    background-color: ${props => props.theme.colors.white};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 55px;
    @media ${props => props.theme.media.desktop} {
        padding-top: 96px;
    }
`;

export const Body = styled(motion.section)`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0;
`;

export const FlexColDiv = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin: 1rem 0;
`;

export const PTypographyBold = styled.div`
    font-weight: bold;
    font-size: .8rem;
    padding-bottom: .25rem;
    @media ${props => props.theme.media.desktop} {
        font-size: .9rem;
    }
`;

export const Hr = styled.hr `
    border: 0;
    border-top: 1px solid ${props => props.theme.colors.grey};
    margin: 10px 0;
    opacity: 30%;
    max-width: 95%;
`;

export const Section = styled.section `
    margin: 0 0.5rem;
    ${Hr} {
        border: none;
    }
`;

export const Subsection = styled.section `
    margin: 2rem 0;
    width: 95%;
`;

export const BlocDoc = styled.div `
    margin: 1rem 0;
    border: 1px solid ${props => props.theme.colors.grey};
    border-radius: 4px;
    display: flex;
`;
