import React from 'react';

const LikeContext = React.createContext({
    likeArray: [],
    unlikeCourse: (course_id) => { },
    likeCourse: (course_id, logo, score) => { }
});

export const LikeProvider = LikeContext.Provider;
export const LikeConsumer = LikeContext.Consumer;

export default LikeContext;

