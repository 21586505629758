import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
// Api
import { getLikesFromHash, getQuoteFromHash, unlikeCourse, likeCourse } from 'providers/api/application-api';
// Provider
import { LikeProvider } from 'providers/context/LikeCoursesContext';
// Components
import Loading from 'components/UI/Loading';
import PageNotFound from 'components/Global/PageNotFound';
import { CurrencyProvider } from "./providers/context/CurrencyContext";

const Home = lazy(() => import('pages/Home'));
const Course = lazy(() => import('pages/Course'));

const App: React.FC<{}> = () => {

    let hash = window.location.pathname.slice(1);

    if (hash.includes('/')) {
        hash = hash.slice(0, hash.indexOf('/'));
    }

    const [currency, setCurrency] = useState('');
    const [likes, setLikes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseLike = await getLikesFromHash(hash);
                const responseQuote = await getQuoteFromHash(hash);

                const responseLikeJson = await responseLike.json();
                const responseQuoteJson = await responseQuote.json();

                // let initialCurrency = responseQuoteJson.student.currency;
                // if (!initialCurrency) {
                //     responseQuoteJson.courses.map((course) => {
                //         if (course.school && course.school.currency && !initialCurrency) {
                //             initialCurrency = course.school.currency;
                //         }
                //     })
                // }
                 // const initialCurrency = responseQuoteJson.baseCurrency;

                let tempCurrencies: any = [];
                if (responseQuoteJson) {
                    if (responseQuoteJson.courses) {
                        responseQuoteJson.courses.map((course) => {
                            if (course.school && course.school.currency) {
                                tempCurrencies.push(course.school.currency);
                            }
                        })
                    }
                    if(responseQuoteJson.student){
                        tempCurrencies.push(responseQuoteJson.student.currency);
                    }
                }
                tempCurrencies = tempCurrencies.filter((v, i) => tempCurrencies.indexOf(v) == i);
               

                if (tempCurrencies.length) {
                    setCurrency(tempCurrencies[0]);
                }

                const finalLikes = responseLikeJson.map(like => {
                    const course = responseQuoteJson.courses.find(course => {
                        return course.course.id === like.course_id;
                    });
                    if (course) {
                        return {
                            course_id: like.course_id,
                            score: like.score,
                            logo: course.school.logo
                        }
                    }
                    return like;
                });

                if (finalLikes) {
                    setLikes(finalLikes);
                }

            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [hash])

    async function unlikeCourseFunction(course_id) {
        try {
            await unlikeCourse(hash, course_id);
            const newLikes = likes.filter((like: any) => {
                return like.course_id !== course_id;
            });
            setLikes(newLikes);
        } catch (error) {
            console.log(error);
        }
    }

    async function likeCourseFunction(course_id, logo, score) {
        try {
            await likeCourse(hash, course_id, score);
            const newLikes: any = [...likes];
            newLikes.push({
                course_id: course_id,
                score: score,
                logo: logo
            });
            setLikes(newLikes);
        } catch (error) {
            console.log(error);
        }
    }

    async function setCurrencyFunction(currency) {
        setCurrency(currency);
    }

    const likeState = {
        likeArray: likes,
        unlikeCourse: unlikeCourseFunction,
        likeCourse: likeCourseFunction
    }

    const currencyState = {
        currency: currency,
        setCurrency: setCurrencyFunction
    }

    return (
        <ThemeProvider theme={theme}>
            <CurrencyProvider value={currencyState}>
                <LikeProvider value={likeState}>
                    <Router>
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route path="/:hash" element={<Home />} />
                                <Route path="/:hash/course/:index" element={<Course />} />
                                <Route path="/" element={<PageNotFound />} />
                                {/* <Navigate to="/" /> */}
                            </Routes>
                        </Suspense>
                    </Router>
                </LikeProvider>
            </CurrencyProvider>
        </ThemeProvider>
    )
}

export default App;
