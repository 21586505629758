import { environment } from 'environments/environment';

const getQuoteFromHash = async (hash, currency= '') => {
    const headers = new Headers();
    headers.append('apiKey', environment.youtooApiKey);

    return fetch(`${environment.baseURL}quotations/${hash}/snapshot?currency_code=${currency}`, {
        headers,
        method: 'GET'
    });
}

const getLikesFromHash = async (hash) => {
    const headers = new Headers();
    headers.append('apiKey', environment.youtooApiKey);

    return fetch(`${environment.baseURL}quotations/${hash}/likes`, {
        headers,
        method: 'GET'
    });
}

const unlikeCourse = async (hash, course_id, score= 0) => {
    const headers = new Headers();
    headers.append('apiKey', environment.youtooApiKey);

    return fetch(`${environment.baseURL}quotations/${hash}/${course_id}/${score}`, {
        headers,
        method: 'POST'
    });
}

const likeCourse = async (hash, course_id, score) => {
    const headers = new Headers();
    headers.append('apiKey', environment.youtooApiKey);

    return fetch(`${environment.baseURL}quotations/${hash}/${course_id}/${score}`, {
        headers,
        method: 'POST'
    });
}

const updateQuoteStatus = async (hash) => {
    const headers = new Headers();
    headers.append('apiKey', environment.youtooApiKey);

    return fetch(`${environment.baseURL}quotations/${hash}/status`, {
        headers,
        method: 'PUT'
    });
}

export { getQuoteFromHash, getLikesFromHash, unlikeCourse, likeCourse, updateQuoteStatus }
