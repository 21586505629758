import React from 'react';
import styled, {keyframes} from 'styled-components';

const LoaderContainer = styled.div  `
    width: 100vw;
    height: 100vh;
    background-image: url( ${process.env.PUBLIC_URL + '/assets/images/loader-bg.png'} );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
`;

const Overlay = styled.div `
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(54,92,169, 0.5);
`;

const grow = keyframes`
    0% {
        bottom: 0;
        height: 25px;
        width: 100px;
        margin-left: -50px;
    }
    50% {
        bottom: 20px;
        margin-left: 0;
        height: 10px;
        width: 15px;
    }
    100% {
        bottom: 0;
        height: 25px;
        width: 100px;
        margin-left: -50px;
    }
`;

const loading = keyframes`
    0% { transform: translate(0, 50%) scale(1.1, 0.5); }
    5% { transform: translate(0, 50%) scale(1.1, 0.5); }
    20% { transform: translate(0, 40%) scale(0.85, 1.1); }
    30% { transform: translate(0, 0%) scale(0.75, 1.3); }
    40% { transform: translate(0, -60%) scale(0.9, 1); }
    45% { transform: translate(0, -75%) scale(1, 1); }
    48% { transform: translate(0, -80%) scale(1, 1); }
    50% { transform: translate(0, -87%) scale(1, 1); }
    52% { transform: translate(0, -80%) scale(1, 1); }
    55% { transform: translate(0, -70%) scale(1, 1); }
    60% { transform: translate(0, -60%) scale(0.95, 1.1); }
    70% { transform: translate(0, -50%) scale(0.85, 1.1); }
    85% { transform: translate(0, 40%) scale(0.9, 1.2); }
    90% { transform: translate(0, 45%) scale(1, 1); }
    100% { transform: translate(0, 50%) scale(1.1, 0.5); }
`;

const LoaderElement = styled.div `
        max-width: 90px;
        display: block;
        width: 100%;
        transform-origin: bottom center;
        animation: ${loading} 1.1s infinite linear;
`;

const LoaderShadow = styled.div `
        background: radial-gradient(closest-side at 50% 50%, rgba(0,0,0,.1) 0%, transparent 100%);
        animation: ${grow} 1.1s infinite linear;
        animation-direction: alternate;
        position: relative;
        left: 20px;
        top: 32px;
        z-index: 1;
        transform-origin: 50% 50%;
`;

const Loading: React.FC = ( () => {
    return (
       <LoaderContainer>
          <Overlay>
            <LoaderElement>
                <img src={process.env.PUBLIC_URL + '/assets/images/brand-logo-solo.png'} />
            </LoaderElement>
            <LoaderShadow />
          </Overlay>
       </LoaderContainer>
   )
});

export default Loading;
