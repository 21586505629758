import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {motion} from 'framer-motion';
// HTML elements
import { Main } from 'components/UI/Layout';
import {H1Typography} from 'components/UI/Headlines';

const Main404 = styled(Main)` 
    @media ${props => props.theme.media.desktop} {
        padding-top: 0;
    }
`;

const Container404 = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

const Img404 = styled(motion.img)`
    width: 150px;
    margin: 0 auto 1rem;
    @media ${props => props.theme.media.desktop} {
        width: 230px;
    }
`;

const Title404 = styled(H1Typography)`
    color: ${props => props.theme.colors.primary};
    margin: 2rem auto 1.5rem;
`;

const LinkToHome = styled.a`
    margin: 1rem auto;
    padding: 1rem 1.5rem;
    border: 1px solid  ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.primary};
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    transition: all .2s ease;
    &:hover, &:active, &:focus {
        background: #f7f7f7;
        border: 1px solid ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.primary};
    }
`;

const Content404 = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const PageNotFound = () => {
    const {t} = useTranslation();
    return (
        <Main404 animate={{ opacity: [0,1] }} transition={{ delay: 0.5 }}>
            <Container404>
                <Img404 src={process.env.PUBLIC_URL + '/assets/images/404.svg'}
                        animate={{ opacity: [0,1], y: [10,0] }}
                        transition={{ delay: 0.8 }}/>
                <Content404 animate={{ opacity: [0,1], y: [10,0] }}
                            transition={{ delay: 1 }}>
                    <Title404>{t('global.page-not-found.title')}</Title404>
                    <LinkToHome href='https://www.youtooproject.com'>{t('global.page-not-found.link')}</LinkToHome>
                </Content404>
            </Container404>
        </Main404>
    );
};

export default PageNotFound;
