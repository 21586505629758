const theme = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1300
    },
    media: {
        get mobile() {
            return `screen and (min-width: ${theme.breakpoints.sm}px)` // Small devices (landscape phones, 576px and up)
        },
        get tablet() {
            return `screen and (min-width: ${theme.breakpoints.md}px)` // Medium devices (tablets, 768px and up)
        },
        get desktop() {
            return `screen and (min-width: ${theme.breakpoints.lg}px)` // Large devices (desktops, 992px and up)
        },
        get desktopLarge() {
            return `screen and (min-width: ${theme.breakpoints.xl}px)` // Extra large devices (large desktops, 1300px and up)
        }
    },
    colors: {
        primary: '#365CA9',
        accent: '#098b4b',
        warn: '#ff3333',
        black: '#121212',
        white:'#f7f7f7',
        disabled: '#8c8c8c',
        grey: '#979797'
    }
};

export default theme;
