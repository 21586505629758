import React from 'react';

const CurrencyContext = React.createContext({
    currency: '',
    setCurrency: (currency) => { },
});

export const CurrencyProvider = CurrencyContext.Provider;
export const CurrencyConsumer = CurrencyContext.Consumer;

export default CurrencyContext;

